//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            news_id: 0,
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image_url: '',
            image: '',
            photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchNews();
      await this.fetchNewsTranslations();
    },
    methods: {
      async updateNews() {

        // update news
        const blob = this.image;
        if(blob) {
          const formData = new FormData();
          formData.append('image', blob);

          const upload = await this.$axios.post(`/s3/upload/`, formData);
          this.image_url = upload.data
        }

        await this.$axios.put(`/news/${this.$route.params.id}`, {
            title: this.title,
            content: this.content,
            image_url: this.image_url
        });
        this.$router.push(`/news`);

        // update news translations
          // CN
          await this.$axios.put(`/news/translations/CN`, {
            news_id: this.news_id,
            title_trans: this.title_cn,
            content_trans: this.content_cn,
          });
          // TW
          await this.$axios.put(`/news/translations/TW`, {
            news_id: this.news_id,
            title_trans: this.title_tw,
            content_trans: this.content_tw,
          });
          // EN
          await this.$axios.put(`/news/translations/EN`, {
            news_id: this.news_id,
            title_trans: this.title_en,
            content_trans: this.content_en,
          });
      },

      async fetchNews() {
        try {
          const res = await this.$axios.get(`/news/${this.$route.params.id}`);
          this.news_id = res.data.id;
          this.title = res.data.title;
          this.content = res.data.content;
          this.image_url = res.data.image_url;
        } catch (err) {
          console.error(err);
        }
      },

      async removeImage() {
        if(this.image_url === '') return
        const url_str_arr = this.image_url.split('/');
        const image_name = url_str_arr.slice(-1);
        try {
          const res = await this.$axios.post(`/s3/delete`, {
            filename: image_name
          });
          console.log(res.data);
          this.image_url = '';
        } catch (err) {
          console.log(err)
        }
      },

      async fetchNewsTranslations() {
        try {
          const translations = await this.$axios.post(`/news/translations`, {
            news_id: this.news_id
          });
          translations.data.forEach(translation => {
            const lang = translation.lang;
            switch(lang) {
              case 'CN': {
                this.title_cn = translation.title_trans
                this.content_cn = translation.content_trans
                break;
              }
              case 'TW': {
                this.title_tw = translation.title_trans
                this.content_tw = translation.content_trans
                break;
              }
              case 'EN': {
                this.title_en = translation.title_trans
                this.content_en = translation.content_trans
                break;
              }
            }
          });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
