//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    components: { Warning },
    layout: 'adminLayout',
    methods: {
      async deleteSustainability() {
        await this.$axios.delete(`sustainability/${this.$route.params.id}`);
        this.$router.push(`/sustainability`);
      },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
