//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            question: '',
            answer: '',
            question_cn: '',
            answer_cn: '',
            question_tw: '',
            answer_tw: '',
            question_en: '',
            answer_en: '',
            qna_id: 0,
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
        };
    },
    async mounted() {
      await this.fetchQna();
      await this.fetchQnaTranslations();
    },
    methods: {
        async addqna() {
          const newqna = await this.$axios.post(`/qnas/create`, {
              question: this.question,
              answer: this.answer
          });

          // Create new qna translations
          await this.$axios.post(`/qnas/translations/create`, {
            qna_id: newqna.data.id,
            params: [
              {
                question_trans: this.question_cn,
                answer_trans: this.answer_cn,
                lang: 'CN',
              },
              {
                question_trans: this.question_tw,
                answer_trans: this.answer_tw,
                lang: 'TW',
              },
              {
                question_trans: this.question_en,
                answer_trans: this.answer_en,
                lang: 'EN',
              },
            ]
          })

          this.$router.push(`/qna`);
        },

      async updateQna() {

        await this.$axios.put(`/qnas/${this.$route.params.id}`, {
            question: this.question,
            answer: this.answer
        });
        this.$router.push(`/qna`);

        // update news translations
          // CN
          await this.$axios.put(`/qnas/translations/CN`, {
            qna_id: this.qna_id,
            question_trans: this.question_cn,
            answer_trans: this.answer_cn,
          });
          // TW
          await this.$axios.put(`/qnas/translations/TW`, {
            qna_id: this.qna_id,
            question_trans: this.question_tw,
            answer_trans: this.answer_tw,
          });
          // EN
          await this.$axios.put(`/qnas/translations/EN`, {
            qna_id: this.qna_id,
            question_trans: this.question_en,
            answer_trans: this.answer_en,
          });
      },

      async fetchQna() {
        try {
          const res = await this.$axios.get(`/qnas/${this.$route.params.id}`);
          this.qna_id = res.data.id;
          this.question = res.data.question;
          this.answer = res.data.answer;
        } catch (err) {
          console.error(err);
        }
      },

      async fetchQnaTranslations() {
        try {
          const translations = await this.$axios.post(`/qnas/translations`, {
            qna_id: this.qna_id
          });
          translations.data.forEach(translation => {
            const lang = translation.lang;
            switch(lang) {
              case 'CN': {
                this.question_cn = translation.question_trans
                this.answer_cn = translation.answer_trans
                break;
              }
              case 'TW': {
                this.question_tw = translation.question_trans
                this.answer_tw = translation.answer_trans
                break;
              }
              case 'EN': {
                this.question_en = translation.question_trans
                this.answer_en = translation.answer_trans
                break;
              }
            }
          });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
