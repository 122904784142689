//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            site_policy_id: 0,
        };
    },
    async mounted() {
      await this.fetchSitePolicy();
      await this.fetchSitePolicyTranslations();
    },
    methods: {
      async updateSitePolicy() {

        await this.$axios.put(`/sitepolicy`, {
            title: this.title,
            content: this.content
        });
        // update service translations
        // CN
        await this.$axios.put(`/sitepolicy/translations/CN`, {
          site_policy_id: this.site_policy_id,
          title_trans: this.title_cn,
          content_trans: this.content_cn,
        });
        // TW
        await this.$axios.put(`/sitepolicy/translations/TW`, {
          site_policy_id: this.site_policy_id,
          title_trans: this.title_tw,
          content_trans: this.content_tw,
        });
        // EN
        await this.$axios.put(`/sitepolicy/translations/EN`, {
          site_policy_id: this.site_policy_id,
          title_trans: this.title_en,
          content_trans: this.content_en,
        });
        this.$router.push(`/sitepolicy`);
      },

      async fetchSitePolicy() {
        try {
          const res = await this.$axios.get(`/sitepolicy`);
          this.site_policy_id = res.data.id;
          this.title = res.data.title;
          this.content = res.data.content;
        } catch (err) {
          console.error(err);
        }
      },

      async fetchSitePolicyTranslations() {
        try {
          const translations = await this.$axios.post(`/sitepolicy/translations`, {
            site_policy_id: this.site_policy_id
          });
          translations.data.forEach(translation => {
            const lang = translation.lang;
            switch(lang) {
              case 'CN': {
                this.title_cn = translation.title_trans
                this.content_cn = translation.content_trans
                break;
              }
              case 'TW': {
                this.title_tw = translation.title_trans
                this.content_tw = translation.content_trans
                break;
              }
              case 'EN': {
                this.title_en = translation.title_trans
                this.content_en = translation.content_trans
                break;
              }
            }
          });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
