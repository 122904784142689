//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CoreView',

    metaInfo() {
        return {
            title: 'Vue Material Dashboard by CreativeTim',
        };
    },
};
