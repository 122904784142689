//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    components: { Warning },
    layout: 'adminLayout',
    data: () => ({
        drawer: null,
        headers: [
            {
                sortable: false,
                text: 'ID',
                value: 'id',
            },
            {
                sortable: false,
                text: '投资信息标题',
                value: 'investorInfo_title',
            },
            {
                sortable: false,
                text: '操作',
                value: 'operations',
            },
        ],
        items: [],
    }),
    async created() {
        try {
            const res = await this.$axios.get(`/investorInfo`);
            this.items = res.data;
        } catch (err) {
            console.error(err);
        }
    },
    methods: {
        editInvestorInfo(investorInfoId) {
            this.$router.push(`/investorInfo/edit/${investorInfoId}`);
        },
        deleteInvestorInfo(investorInfoId) {
            this.$router.push(`/investorInfo/delete/${investorInfoId}`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
