//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    layout: 'adminLayout',
    components: { Warning, VuePdfEmbed },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image_url: '',
            sustainability_id: 0,
            image: '',
            photoRules: [v => !v || v.size < 5000000 || '上传的PDF文件不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchSustainability();
      await this.fetchSustainabilityTranslations();
    },
    methods: {
      async updateSustainability() {

        const blob = this.image;
        if(blob) {
          const formData = new FormData();
          formData.append('image', blob);

          const upload = await this.$axios.post(`/s3/upload/`, formData);
          this.image_url = upload.data
        }

        // Update existing sustainability
        await this.$axios.put(`/sustainability`, {
            id: this.sustainability_id,
            title: this.title,
            content: this.content,
            image_url: this.image_url
        });

        // update sustainability translations
        // CN
        await this.$axios.put(`/sustainability/translations/CN`, {
          sustainability_id: this.sustainability_id,
          title_trans: this.title_cn,
          content_trans: this.content_cn,
        });
        // TW
        await this.$axios.put(`/sustainability/translations/TW`, {
          sustainability_id: this.sustainability_id,
          title_trans: this.title_tw,
          content_trans: this.content_tw,
        });
        // EN
        await this.$axios.put(`/sustainability/translations/EN`, {
          sustainability_id: this.sustainability_id,
          title_trans: this.title_en,
          content_trans: this.content_en,
        });

        this.$router.push(`/sustainability`);
      },

      async removeImage() {
        if(this.image_url === '') return
        const url_str_arr = this.image_url.split('/');
        const image_name = url_str_arr.slice(-1);
        try {
          const res = await this.$axios.post(`/s3/delete`, {
            filename: image_name
          });
          this.image_url = '';
        } catch (err) {
          console.log(err)
        }
      },

      async fetchSustainability() {
        try {
          const res = await this.$axios.get(`/sustainability/${this.$route.params.id}`);
          if(!res.data) return;
          this.sustainability_id = res.data.id;
          this.title = res.data.title;
          this.content = res.data.content;
          this.image_url = res.data.image_url;
        } catch (err) {
          console.error(err);
        }
      },

      async fetchSustainabilityTranslations() {
        try {
          console.log('this.sustainability_id', this.sustainability_id);
          const translations = await this.$axios.post(`/sustainability/translations`, {
            sustainability_id: this.sustainability_id
          });
          translations.data.forEach(translation => {
            const lang = translation.lang;
            switch(lang) {
              case 'CN': {
                this.title_cn = translation.title_trans
                this.content_cn = translation.content_trans
                break;
              }
              case 'TW': {
                this.title_tw = translation.title_trans
                this.content_tw = translation.content_trans
                break;
              }
              case 'EN': {
                this.title_en = translation.title_trans
                this.content_en = translation.content_trans
                break;
              }
            }
          });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
