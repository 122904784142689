//
//
//
//
//
//

export default {
    name: 'MaterialNotification',

    inheritAttrs: false,

    props: {
        dark: {
            type: Boolean,
            default: true,
        },
        elevation: {
            type: [Number, String],
            default: 6,
        },
        value: {
            type: Boolean,
            default: true,
        },
    },
};
