//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
          title: '',
          overview: '',
          description: '',
          primary_requirements: '',
          additional_requirements: '',
          benefits: '',
          title_cn: '',
          overview_cn: '',
          description_cn: '',
          primary_requirements_cn: '',
          additional_requirements_cn: '',
          benefits_cn: '',
          title_tw: '',
          overview_tw: '',
          description_tw: '',
          primary_requirements_tw: '',
          additional_requirements_tw: '',
          benefits_tw: '',
          title_en: '',
          overview_en: '',
          description_en: '',
          primary_requirements_en: '',
          additional_requirements_en: '',
          benefits_en: '',
          isFormValid: false,
          formRules: [(v) => !!v || '该内容必须填写'],
        };
    },
    methods: {
        async addNewJobPost() {
          // Create new Job Post
          const newCreate = await this.$axios.post(`/jobPost/create`, {
              title: this.title,
              overview: this.overview,
              description: this.description,
              primary_requirements: this.primary_requirements,
              additional_requirements: this.additional_requirements,
              benefits: this.benefits,
          });
          // Create new Job Post translations
          await this.$axios.post(`/jobPost/translations/create`, {
            job_post_id: newCreate.data.id,
            params: [
              {
                title_trans: this.title_cn,
                overview_trans: this.overview_cn,
                description_trans: this.description_cn,
                primary_requirements_trans: this.primary_requirements_cn,
                additional_requirements_trans: this.additional_requirements_cn,
                benefits_trans: this.benefits_cn,
                lang: 'CN',
              },
              {
                title_trans: this.title_tw,
                overview_trans: this.overview_tw,
                description_trans: this.description_tw,
                primary_requirements_trans: this.primary_requirements_tw,
                additional_requirements_trans: this.additional_requirements_tw,
                benefits_trans: this.benefits_tw,
                lang: 'TW',
              },
              {
                title_trans: this.title_en,
                overview_trans: this.overview_en,
                description_trans: this.description_en,
                primary_requirements_trans: this.primary_requirements_en,
                additional_requirements_trans: this.additional_requirements_en,
                benefits_trans: this.benefits_en,
                lang: 'EN',
              },
            ]
          })

          this.$router.push(`/jobpost`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
