//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            question: '',
            answer: '',
            question_cn: '',
            answer_cn: '',
            question_tw: '',
            answer_tw: '',
            question_en: '',
            answer_en: '',
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
        };
    },
    methods: {
        async addqna() {
          const newqna = await this.$axios.post(`/qnas/create`, {
              question: this.question,
              answer: this.answer
          });

          // Create new qna translations
          await this.$axios.post(`/qnas/translations/create`, {
            qna_id: newqna.data.id,
            params: [
              {
                question_trans: this.question_cn,
                answer_trans: this.answer_cn,
                lang: 'CN',
              },
              {
                question_trans: this.question_tw,
                answer_trans: this.answer_tw,
                lang: 'TW',
              },
              {
                question_trans: this.question_en,
                answer_trans: this.answer_en,
                lang: 'EN',
              },
            ]
          })

          this.$router.push(`/qna`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
