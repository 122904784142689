//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            contact_id: 0,
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
        };
    },
    async mounted() {
      await this.fetchContact();
      await this.fetchContactTranslations();
    },
    methods: {
        async updateContact() {

          // update contact
          await this.$axios.put(`/contacts/${this.$route.params.id}`, {
            title: this.title,
            content: this.content,
          });

          // update contact translations
          // CN
          await this.$axios.put(`/contacts/translations/CN`, {
            contact_id: this.contact_id,
            title_trans: this.title_cn,
            content_trans: this.content_cn,
          });
          // TW
          await this.$axios.put(`/contacts/translations/TW`, {
            contact_id: this.contact_id,
            title_trans: this.title_tw,
            content_trans: this.content_tw,
          });
          // EN
          await this.$axios.put(`/contacts/translations/EN`, {
            contact_id: this.contact_id,
            title_trans: this.title_en,
            content_trans: this.content_en,
          });
          this.$router.push(`/contacts`);
        },
        async fetchContact() {
          try {
              const res = await this.$axios.get(`/contacts/${this.$route.params.id}`);
              this.contact_id = res.data.id;
              this.title = res.data.title;
              this.content = res.data.content;
          } catch (err) {
              console.error(err);
          }
        },
        async fetchContactTranslations() {
          try {
            const translations = await this.$axios.post(`/contacts/translations`, {
              contact_id: this.contact_id
            });
            translations.data.forEach(translation => {
              const lang = translation.lang;
              switch(lang) {
                case 'CN': {
                  this.title_cn = translation.title_trans
                  this.content_cn = translation.content_trans
                  break;
                }
                case 'TW': {
                  this.title_tw = translation.title_trans
                  this.content_tw = translation.content_trans
                  break;
                }
                case 'EN': {
                  this.title_en = translation.title_trans
                  this.content_en = translation.content_trans
                  break;
                }
              }
            });
          } catch (err) {
              console.error(err);
          }
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
