//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
          isFormValid: false,
          formRules: [(v) => !!v || '该内容必须填写'],
          title: '',
          name: '',
          desc: '',
          title_cn: '',
          name_cn: '',
          desc_cn: '',
          title_tw: '',
          name_tw: '',
          desc_tw: '',
          title_en: '',
          name_en: '',
          desc_en: '',
          team_member_id: 0,
          image_url: '',
          image: '',
          photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchTeamMember();
      await this.fetchTeamMemberTranslations();
    },
    methods: {
      async updateTeamMember() {

        const blob = this.image;
          if(blob) {
            const formData = new FormData();
            formData.append('image', blob);

            const upload = await this.$axios.post(`/s3/upload/`, formData);
            this.image_url = upload.data
          }

        await this.$axios.put(`/teammembers/${this.$route.params.id}`, {
          title: this.title,
          name: this.name,
          desc: this.desc,
          image_url: this.image_url
        });

        // update teammember translations
        // CN
        await this.$axios.put(`/teammembers/translations/CN`, {
          team_member_id: this.team_member_id,
          title_trans: this.title_cn,
          name_trans: this.name_cn,
          desc_trans: this.desc_cn
        });
        // TW
        await this.$axios.put(`/teammembers/translations/TW`, {
          team_member_id: this.team_member_id,
          title_trans: this.title_tw,
          name_trans: this.name_tw,
          desc_trans: this.desc_tw,
        });
        // EN
        await this.$axios.put(`/teammembers/translations/EN`, {
          team_member_id: this.team_member_id,
          title_trans: this.title_en,
          name_trans: this.name_en,
          desc_trans: this.desc_en,
        });
        this.$router.push(`/teammembers`);
      },

      async removeImage() {
        if(this.image_url === '') return
        const url_str_arr = this.image_url.split('/');
        const image_name = url_str_arr.slice(-1);
        try {
          const res = await this.$axios.post(`/s3/delete`, {
            filename: image_name
          });
          this.image_url = '';
        } catch (err) {
          console.log(err)
        }
      },

      async fetchTeamMember() {
          try {
              const res = await this.$axios.get(`/teammembers/${this.$route.params.id}`);
              this.team_member_id = res.data.id;
              this.title = res.data.title;
              this.name = res.data.name;
              this.desc = res.data.desc;
              this.image_url = res.data.image_url;
          } catch (err) {
              console.error(err);
          }
        },
        async fetchTeamMemberTranslations() {
          try {
            const translations = await this.$axios.post(`/teammembers/translations`, {
              team_member_id: this.team_member_id
            });
            translations.data.forEach(translation => {
              const lang = translation.lang;
              switch(lang) {
                case 'CN': {
                  this.title_cn = translation.title_trans
                  this.name_cn = translation.name_trans
                  this.desc_cn = translation.desc_trans
                  break;
                }
                case 'TW': {
                  this.title_tw = translation.title_trans
                  this.name_tw = translation.name_trans
                  this.desc_tw = translation.desc_trans
                  break;
                }
                case 'EN': {
                  this.title_en = translation.title_trans
                  this.name_en = translation.name_trans
                  this.desc_en = translation.desc_trans
                  break;
                }
              }
            });
          } catch (err) {
              console.error(err);
          }
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
