//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Notification from '@/components/Notification';

export default {
    layout: 'adminLayout',
    components: {
        Notification,
    },

    data() {
        return {
            username: '',
            password: '',
            error: null,
        };
    },

    methods: {
        async login() {
            try {
                await this.$auth.loginWith('local', {
                    data: {
                        username: this.username,
                        password: this.password,
                    },
                });

                this.$router.push('/');
            } catch (e) {
                this.error = e.response.data.error;
            }
        },
    },
};
