//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    components: { Warning },
    layout: 'adminLayout',
    data: () => ({
        drawer: null,
        headers: [
            {
                sortable: false,
                text: 'ID',
                value: 'id',
            },
            {
                sortable: false,
                text: '新闻标题',
                value: 'title',
            },
            {
                sortable: false,
                text: '操作',
                value: 'operations',
            },
        ],
        items: [],
    }),
    async created() {
        try {
            const res = await this.$axios.get(`/news`);
            this.items = res.data;
        } catch (err) {
            console.error(err);
        }
    },
    methods: {
        editNews(newsId) {
            this.$router.push(`/news/edit/${newsId}`);
        },
        deleteNews(newsId) {
            this.$router.push(`/news/delete/${newsId}`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
