//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    layout: 'adminLayout',
    components: { Warning, VuePdfEmbed },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            investor_info_id: 0,
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image: '',
            pdf_file_url: '',
            fileRules: [v => !v || v.size < 5000000 || '上传的PDF文件不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchInvestorInfo();
      await this.fetchInvestorInfoTranslations();
    },
    methods: {
        async updateInvestorInfo() {
            const blob = this.image;
            if(blob) {
              const formData = new FormData();
              formData.append('image', blob);

              const upload = await this.$axios.post(`/s3/upload/`, formData);
              this.pdf_file_url = upload.data
            }
          // update investor info
          await this.$axios.put(`/investorInfo/${this.$route.params.id}`, {
            title: this.title,
            content: this.content,
            pdf_file_url: this.pdf_file_url
          });

          // update investor info translations
          // CN
          await this.$axios.put(`/investorInfo/translations/CN`, {
            investor_info_id: this.investor_info_id,
            title_trans: this.title_cn,
            content_trans: this.content_cn,
          });
          // TW
          await this.$axios.put(`/investorInfo/translations/TW`, {
            investor_info_id: this.investor_info_id,
            title_trans: this.title_tw,
            content_trans: this.content_tw,
          });
          // EN
          await this.$axios.put(`/investorInfo/translations/EN`, {
            investor_info_id: this.investor_info_id,
            title_trans: this.title_en,
            content_trans: this.content_en,
          });
          this.$router.push(`/investorInfo`);
        },

        async fetchInvestorInfoTranslations() {
          try {
            const translations = await this.$axios.post(`/investorInfo/translations`, {
              investor_info_id: this.investor_info_id
            });
            translations.data.forEach(translation => {
              const lang = translation.lang;
              switch(lang) {
                case 'CN': {
                  this.title_cn = translation.title_trans
                  this.content_cn = translation.content_trans
                  break;
                }
                case 'TW': {
                  this.title_tw = translation.title_trans
                  this.content_tw = translation.content_trans
                  break;
                }
                case 'EN': {
                  this.title_en = translation.title_trans
                  this.content_en = translation.content_trans
                  break;
                }
              }
            });
          } catch (err) {
              console.error(err);
          }
        },

        async removeFile() {
          if(this.pdf_file_url === '') return
          const url_str_arr = this.pdf_file_url.split('/');
          const image_name = url_str_arr.slice(-1);
          try {
            const res = await this.$axios.post(`/s3/delete`, {
              filename: image_name
            });
            this.image = '';
            this.pdf_file_url = '';
          } catch (err) {
            console.log(err)
          }
        },

        async fetchInvestorInfo() {
          try {
              const res = await this.$axios.get(`/investorInfo/${this.$route.params.id}`);
              this.investor_info_id = res.data.id;
              this.title = res.data.title;
              this.content = res.data.content;
              this.pdf_file_url = res.data.pdf_file_url;
          } catch (err) {
              console.error(err);
          }
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
