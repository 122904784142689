//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data: () => ({
        drawer: null,
        headers: [
            {
                sortable: false,
                text: 'ID',
                value: 'id',
            },
            {
                sortable: false,
                text: '团队成员职位',
                value: 'title',
            },
            {
                sortable: false,
                text: '操作',
                value: 'operations',
            },
        ],
        items: [],
    }),
    async created() {
        try {
            const res = await this.$axios.get(`/teammembers`);
            this.items = res.data;
        } catch (err) {
            console.error(err);
        }
    },
    methods: {
        editTeamMember(teamMemberId) {
            this.$router.push(`/teammembers/edit/${teamMemberId}`);
        },
        deleteTeamMember(teamMemberId) {
            this.$router.push(`/teammembers/delete/${teamMemberId}`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
