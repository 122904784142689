//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            title: '',
            content: '',
            news_date: '2020-01-01',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            image: '',
            image_url: '',
            photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    methods: {
        async addNews() {

          const blob = this.image;
          if(blob) {
            const formData = new FormData();
            formData.append('image', blob);

            const upload = await this.$axios.post(`/s3/upload/`, formData);
            this.image_url = upload.data
          }

          const createdNews = await this.$axios.post(`/news/create`, {
              title: this.title,
              content: this.content,
              image_url: this.image_url,
              news_date: this.news_date
          });

          // Create new news translations
          await this.$axios.post(`/news/translations/create`, {
            news_id: createdNews.data.id,
            params: [
              {
                title_trans: this.title_cn,
                content_trans: this.content_cn,
                lang: 'CN',
              },
              {
                title_trans: this.title_tw,
                content_trans: this.content_tw,
                lang: 'TW',
              },
              {
                title_trans: this.title_en,
                content_trans: this.content_en,
                lang: 'EN',
              },
            ]
          })
          this.$router.push(`/news`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
