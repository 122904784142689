//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image_url: '',
            sustainability_id: 0,
            image: '',
            photoRules: [v => !v || v.size < 5000000 || '上传的PDF文件不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchSustainability();
      await this.fetchSustainabilityTranslations();
    },
    methods: {
      async createSustainability() {

        const blob = this.image;
        if(blob) {
          const formData = new FormData();
          formData.append('image', blob);

          const upload = await this.$axios.post(`/s3/upload/`, formData);
          this.image_url = upload.data
        }

          // Create new sustainability
          const newSustainability = await this.$axios.post(`/sustainability/create`, {
            title: this.title,
            content: this.content,
            image_url: this.image_url
          });
          // Create new sustainability translations
          await this.$axios.post(`/sustainability/translations/create`, {
            sustainability_id: newSustainability.data.id,
            params: [
              {
                title_trans: this.title_cn,
                content_trans: this.content_cn,
                lang: 'CN',
              },
              {
                title_trans: this.title_tw,
                content_trans: this.content_tw,
                lang: 'TW',
              },
              {
                title_trans: this.title_en,
                content_trans: this.content_en,
                lang: 'EN',
              },
            ]
          });
        this.$router.push(`/sustainability`);
      },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
