//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
          about_title: '',
          content: '',
          about_title_cn: '',
          content_cn: '',
          about_title_tw: '',
          content_tw: '',
          about_title_en: '',
          content_en: '',
          isFormValid: false,
          formRules: [(v) => !!v || '该内容必须填写'],
          image: '',
          image_url: '',
          photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    methods: {
        async addNewAbout() {

          const blob = this.image;
          if(blob) {
            const formData = new FormData();
            formData.append('image', blob);

            const upload = await this.$axios.post(`/s3/upload/`, formData);
            this.image_url = upload.data
          }
          // Create new about
          const newCreate = await this.$axios.post(`/about/create`, {
              title: this.about_title,
              content: this.content,
              image_url: this.image_url
          });
          // Create new about translations
          await this.$axios.post(`/about/translations/create`, {
            about_id: newCreate.data.id,
            params: [
              {
                title_trans: this.about_title_cn,
                content_trans: this.content_cn,
                lang: 'CN',
              },
              {
                title_trans: this.about_title_tw,
                content_trans: this.content_tw,
                lang: 'TW',
              },
              {
                title_trans: this.about_title_en,
                content_trans: this.content_en,
                lang: 'EN',
              },
            ]
          })

          this.$router.push(`/about`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
