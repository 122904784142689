//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
          job_post_id: 0,
          title: '',
          overview: '',
          description: '',
          primary_requirements: '',
          additional_requirements: '',
          benefits: '',
          title_cn: '',
          overview_cn: '',
          description_cn: '',
          primary_requirements_cn: '',
          additional_requirements_cn: '',
          benefits_cn: '',
          title_tw: '',
          overview_tw: '',
          description_tw: '',
          primary_requirements_tw: '',
          additional_requirements_tw: '',
          benefits_tw: '',
          title_en: '',
          overview_en: '',
          description_en: '',
          primary_requirements_en: '',
          additional_requirements_en: '',
          benefits_en: '',
          isFormValid: false,
          formRules: [(v) => !!v || '该内容必须填写'],
        };
    },
    async mounted() {
      await this.fetchJobPost();
      await this.fetchJobPostTranslations();
    },
    methods: {
      async updateJobPost() {
        // update JobPost
        await this.$axios.put(`/jobPost/${this.$route.params.id}`, {
            title: this.title,
            overview: this.overview,
            description: this.description,
            primary_requirements: this.primary_requirements,
            additional_requirements: this.additional_requirements,
            benefits: this.benefits,
        });

        // update Job Post translations
        // CN
        await this.$axios.put(`/jobPost/translations/CN`, {
          job_post_id: this.$route.params.id,
          title_trans: this.title_cn,
          overview_trans: this.overview_cn,
          description_trans: this.description_cn,
          primary_requirements_trans: this.primary_requirements_cn,
          additional_requirements_trans: this.additional_requirements_cn,
          benefits_trans: this.benefits_cn,
        });
        // TW
        await this.$axios.put(`/jobPost/translations/TW`, {
          job_post_id: this.$route.params.id,
          title_trans: this.title_tw,
          overview_trans: this.overview_tw,
          description_trans: this.description_tw,
          primary_requirements_trans: this.primary_requirements_tw,
          additional_requirements_trans: this.additional_requirements_tw,
          benefits_trans: this.benefits_tw,
        });
        // EN
        await this.$axios.put(`/jobPost/translations/EN`, {
          job_post_id: this.$route.params.id,
          title_trans: this.title_en,
          overview_trans: this.overview_en,
          description_trans: this.description_en,
          primary_requirements_trans: this.primary_requirements_en,
          additional_requirements_trans: this.additional_requirements_en,
          benefits_trans: this.benefits_en,
        });

        this.$router.push(`/jobpost`);
      },

      async fetchJobPost() {
        try {
            const res = await this.$axios.get(`/jobPost/${this.$route.params.id}`);
            this.job_post_id = res.data.id;
            this.title = res.data.title;
            this.overview = res.data.overview;
            this.description = res.data.description;
            this.primary_requirements = res.data.primary_requirements;
            this.additional_requirements = res.data.additional_requirements;
            this.benefits = res.data.benefits;
        } catch (err) {
            console.error(err);
        }
      },

      async fetchJobPostTranslations() {
        try {
            const translations = await this.$axios.post(`/jobPost/translations`, {
              job_post_id: this.$route.params.id
            });
            translations.data.forEach(translation => {
              const lang = translation.lang;
              switch(lang) {
                case 'CN': {
                  this.title_cn = translation.title_trans
                  this.overview_cn = translation.overview_trans
                  this.description_cn = translation.description_trans
                  this.primary_requirements_cn = translation.primary_requirements_trans
                  this.additional_requirements_cn = translation.additional_requirements_trans
                  this.benefits_cn = translation.benefits_trans
                  break;
                }
                case 'TW': {
                  this.title_tw = translation.title_trans
                  this.overview_tw = translation.overview_trans
                  this.description_tw = translation.description_trans
                  this.primary_requirements_tw = translation.primary_requirements_trans
                  this.additional_requirements_tw = translation.additional_requirements_trans
                  this.benefits_tw = translation.benefits_trans
                  break;
                }
                case 'EN': {
                  this.title_en = translation.title_trans
                  this.overview_en = translation.overview_trans
                  this.description_en = translation.description_trans
                  this.primary_requirements_en = translation.primary_requirements_trans
                  this.additional_requirements_en = translation.additional_requirements_trans
                  this.benefits_en = translation.benefits_trans
                  break;
                }
              }
            });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};

