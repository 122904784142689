//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'companyInfo',
  layout: 'adminLayout',
  components: { Warning },
  data() {
    return {
      dialogUpdate: false,
      items: [],
      isValidated: true,
      toEditFormData: {},
      toEditFormRules: {
        nameRules: [
          (v) => !!v || '企业名称不可为空',
          (v) => (v && v.length >= 3) || '企业名称需要至少3个字符或以上'
        ]
      },
      headers: [
        { text: '企业名称', value: 'name', align: 'left' },
        { text: '语言', value: 'lang', align: 'left' },
        { text: '注册地址', value: 'address', align: 'left' },
        { text: '成立日期', value: 'established_date', align: 'left' },
        { text: '注册资本', value: 'fund', align: 'left' },
        { text: '主业务', value: 'business_content', align: 'left' },
        { text: '里程碑', value: 'milestones', align: 'left' },
        { text: '操作', value: 'actions', align: 'left', sortable: false }
      ],
    }
  },
  computed: {},
  watch: {
    dialogUpdate(val) {
      val || this.close();
    }
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      try {
        const url = '/companyInfo';
        const res = await this.$axios.get(url);
        this.items = res.data;
      } catch (error) {
        console.error('[Error] in order history getItems', error);
      }
    },
    async submitForm() {
      this.$refs.form.validate();
      await this.updateCompanyInfo(this.toEditFormData);
      this.close();
    },
    async updateCompanyInfo(cInfo) {
      try {
        await this.$axios.put(`/companyInfo/${cInfo.lang}`, { params: cInfo });
        await this.getItems();
      } catch (error) {
        this.pause(error.response.data);
      }
    },
    editItem(item) {
      this.toEditFormData = item;
      this.dialogUpdate = true;
    },
    close() {
      this.dialogUpdate = false;
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
  },
}

