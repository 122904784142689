//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image: '',
            pdf_file_url: '',
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            fileRules: [v => !v || v.size < 5000000 || '上传的PDF文件不可以超过5MB'],
        };
    },
    methods: {
      // Create new Investor Info
        async addNewInvestorInfo() {
            const blob = this.image;
            if(blob) {
              const formData = new FormData();
              formData.append('image', blob);

              const upload = await this.$axios.post(`/s3/upload/`, formData);
              this.pdf_file_url = upload.data
            }
            const newInvestorInfo = await this.$axios.post(`/investorInfo/create`, {
                title: this.title,
                content: this.content,
                pdf_file_url: this.pdf_file_url
            });
            // Create new Investor Info translations
            await this.$axios.post(`/investorInfo/translations/create`, {
              investor_info_id: newInvestorInfo.data.id,
              params: [
                {
                  title_trans: this.title_cn,
                  content_trans: this.content_cn,
                  lang: 'CN',
                },
                {
                  title_trans: this.title_tw,
                  content_trans: this.content_tw,
                  lang: 'TW',
                },
                {
                  title_trans: this.title_en,
                  content_trans: this.content_en,
                  lang: 'EN',
                },
              ]
            })
            this.$router.push(`/investorInfo`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
