//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    components: { Warning },
    layout: 'adminLayout',
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
