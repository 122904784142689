//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
      return {
        title: '',
        name: '',
        desc: '',
        title_cn: '',
        name_cn: '',
        desc_cn: '',
        title_tw: '',
        name_tw: '',
        desc_tw: '',
        title_en: '',
        name_en: '',
        desc_en: '',
        team_member_id: 0,
        isFormValid: false,
        formRules: [(v) => !!v || '该内容必须填写'],
        image: '',
        image_url: '',
        photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
      };
    },
    methods: {
        async addNewTeamMember() {

          const blob = this.image;
          if(blob) {
            const formData = new FormData();
            formData.append('image', blob);

            const upload = await this.$axios.post(`/s3/upload/`, formData);
            this.image_url = upload.data
          }

          const newTeamMember = await this.$axios.post(`/teammembers/create`, {
              title: this.title,
              name: this.name,
              desc: this.desc,
              image_url: this.image_url
          });

          // Create new teammember translations
          await this.$axios.post(`/teammembers/translations/create`, {
            team_member_id: newTeamMember.data.id,
            params: [
              {
                title_trans: this.title_cn,
                name_trans: this.name_cn,
                desc_trans: this.desc_cn,
                lang: 'CN',
              },
              {
                title_trans: this.title_tw,
                name_trans: this.name_tw,
                desc_trans: this.desc_tw,
                lang: 'TW',
              },
              {
                title_trans: this.title_en,
                name_trans: this.name_en,
                desc_trans: this.desc_en,
                lang: 'EN',
              },
            ]
          })
          this.$router.push(`/teammembers`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
