//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
          isFormValid: false,
          formRules: [(v) => !!v || '该内容必须填写'],
          about_id: 0,
          about_title: '',
          content: '',
          about_title_cn: '',
          content_cn: '',
          about_title_tw: '',
          content_tw: '',
          about_title_en: '',
          content_en: '',
          image_url: '',
          image: '',
          photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchAbout();
      await this.fetchAboutTranslations();
    },
    methods: {
      async updateAbout() {

        const blob = this.image;
          if(blob) {
            const formData = new FormData();
            formData.append('image', blob);

            const upload = await this.$axios.post(`/s3/upload/`, formData);
            this.image_url = upload.data
          }

        // update about
        await this.$axios.put(`/about/${this.$route.params.id}`, {
          title: this.about_title,
          content: this.content,
          image_url: this.image_url
        });

        // update about translations
        // CN
        await this.$axios.put(`/about/translations/CN`, {
          about_id: this.$route.params.id,
          title_trans: this.about_title_cn,
          content_trans: this.content_cn,
        });
        // TW
        await this.$axios.put(`/about/translations/TW`, {
          about_id: this.$route.params.id,
          title_trans: this.about_title_tw,
          content_trans: this.content_tw,
        });
        // EN
        await this.$axios.put(`/about/translations/EN`, {
          about_id: this.$route.params.id,
          title_trans: this.about_title_en,
          content_trans: this.content_en,
        });

        this.$router.push(`/about`);
      },

      async fetchAbout() {
        try {
            const res = await this.$axios.get(`/about/${this.$route.params.id}`);
            this.about_id = res.data.id;
            this.about_title = res.data.title;
            this.content = res.data.content;
            this.image_url = res.data.image_url;
        } catch (err) {
            console.error(err);
        }
      },

      async removeImage() {
        if(this.image_url === '') return
        const url_str_arr = this.image_url.split('/');
        const image_name = url_str_arr.slice(-1);
        try {
          const res = await this.$axios.post(`/s3/delete`, {
            filename: image_name
          });
          this.image_url = '';
        } catch (err) {
          console.log(err)
        }
      },

      async fetchAboutTranslations() {
        try {
            const translations = await this.$axios.post(`/about/translations`, {
              about_id: this.$route.params.id
            });
            translations.data.forEach(translation => {
              const lang = translation.lang;
              switch(lang) {
                case 'CN': {
                  this.about_title_cn = translation.title_trans
                  this.content_cn = translation.content_trans
                  break;
                }
                case 'TW': {
                  this.about_title_tw = translation.title_trans
                  this.content_tw = translation.content_trans
                  break;
                }
                case 'EN': {
                  this.about_title_en = translation.title_trans
                  this.content_en = translation.content_trans
                  break;
                }
              }
            });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};

