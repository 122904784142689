//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from '@/components/Warning.vue';
import { mapGetters } from 'vuex';

export default {
    components: { Warning },
    layout: 'adminLayout',
    methods: {
        async deleteJobPost() {
            await this.$axios.delete(`jobPost/${this.$route.params.id}`);
            this.$router.push(`/jobpost`);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
