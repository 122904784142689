//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            content: '',
            title: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
        };
    },
    methods: {
      async addNewContact() {
        // Create new contact
        const newContact = await this.$axios.post(`/contacts/create`, {
            title: this.title,
            content: this.content,
        });
        // Create new contact translations
          await this.$axios.post(`/contacts/translations/create`, {
            contact_id: newContact.data.id,
            params: [
              {
                title_trans: this.title_cn,
                content_trans: this.content_cn,
                lang: 'CN',
              },
              {
                title_trans: this.title_tw,
                content_trans: this.content_tw,
                lang: 'TW',
              },
              {
                title_trans: this.title_en,
                content_trans: this.content_en,
                lang: 'EN',
              },
            ]
          })
        this.$router.push(`/contacts`);
      },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
