//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Utilities
import { mapMutations, mapState } from 'vuex';

export default {
    props: {
        opened: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        links: [
            {
                title: '产品服务',
                child: [
                    {
                        subtitle: '服务列表',
                        icon: 'mdi-table',
                        link: '/services',
                    },
                    {
                        subtitle: '添加新服务',
                        icon: 'mdi-playlist-plus',
                        link: '/services/create',
                    },
                ],
            },
            {
                title: '企业信息',
                child: [
                    {
                        subtitle: '信息列表',
                        icon: 'mdi-table',
                        link: '/about',
                    },
                    {
                        subtitle: '添加信息',
                        icon: 'mdi-playlist-plus',
                        link: '/about/create',
                    }
                ],
            },
            {
                title: '公司概要',
                icon: 'mdi-table',
                child: [
                  {
                    subtitle: '公司概要',
                    icon: 'mdi-playlist-plus',
                    link: '/about/companyInfo',
                  }
                ]
            },
            {
                title: '团队成员',
                child: [
                    {
                        subtitle: '成员列表',
                        icon: 'mdi-table',
                        link: '/teammembers',
                    },
                    {
                        subtitle: '添加新成员',
                        icon: 'mdi-playlist-plus',
                        link: '/teammembers/create',
                    },
                ],
            },
            {
                title: '网站政策',
                child: [
                    {
                        subtitle: '网站政策',
                        icon: 'mdi-table',
                        link: '/sitepolicy',
                    },
                ],
            },
            {
                title: '联系我们',
                child: [
                    {
                        subtitle: '联系列表',
                        icon: 'mdi-table',
                        link: '/contacts',
                    },
                    {
                        subtitle: '添加联系方式',
                        icon: 'mdi-playlist-plus',
                        link: '/contacts/create',
                    },
                ],
            },
            {
                title: '新闻通知',
                child: [
                    {
                        subtitle: '新闻列表',
                        icon: 'mdi-table',
                        link: '/news',
                    },
                    {
                        subtitle: '添加新闻',
                        icon: 'mdi-playlist-plus',
                        link: '/news/create',
                    },
                ],
            },
            {
                title: '加入我们',
                child: [
                    {
                        subtitle: '招聘信息列表',
                        icon: 'mdi-table',
                        link: '/jobpost',
                    },
                    {
                        subtitle: '添加新的招聘信息',
                        icon: 'mdi-playlist-plus',
                        link: '/jobpost/create',
                    },
                ],
            },
            { title: '常见疑问',
                child: [
                    {
                        subtitle: '疑问列表',
                        icon: 'mdi-table',
                        link: '/qna',
                    },
                    {
                        subtitle: '添加疑问',
                        icon: 'mdi-playlist-plus',
                        link: '/qna/create',
                    },
                ],
            },
            {
                title: '投资者信息',
                child: [
                    {
                        subtitle: '公告列表',
                        icon: 'mdi-table',
                        link: '/investorInfo',
                    },
                    {
                        subtitle: '添加公告',
                        icon: 'mdi-playlist-plus',
                        link: '/investorInfo/create',
                    },
                ],
            },
            {
                title: '可持续发展',
                child: [
                    {
                        subtitle: '可持续发展',
                        icon: 'mdi-table',
                        link: '/sustainability',
                    },
                                        {
                        subtitle: '添加可持续发展',
                        icon: 'mdi-playlist-plus',
                        link: '/sustainability/create',
                    },
                ],
            }
        ],
    }),

    computed: {
        ...mapState('app', ['image', 'color']),
        inputValue: {
            get() {
                return this.$store.state.app.drawer;
            },
            set(val) {
                this.setDrawer(val);
            },
        },
    },

    methods: {
        ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    },
};
