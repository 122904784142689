//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import Warning from '@/components/Warning.vue';

export default {
    layout: 'adminLayout',
    components: { Warning },
    data() {
        return {
            isFormValid: false,
            formRules: [(v) => !!v || '该内容必须填写'],
            service_id: 0,
            title: '',
            content: '',
            title_cn: '',
            content_cn: '',
            title_tw: '',
            content_tw: '',
            title_en: '',
            content_en: '',
            image_url: '',
            image: '',
            photoRules: [v => !v || v.size < 5000000 || '上传的图片不可以超过5MB'],
        };
    },
    async mounted() {
      await this.fetchService();
      await this.fetchServiceTranslations();
    },
    methods: {
      async updateService() {

        const blob = this.image;
        if(blob) {
          const formData = new FormData();
          formData.append('image', blob);

          const upload = await this.$axios.post(`/s3/upload/`, formData);
          this.image_url = upload.data
        }

        await this.$axios.put(`/services/${this.$route.params.id}`, {
            title: this.title,
            content: this.content,
            image_url: this.image_url
        });

        // update service translations
        // CN
        await this.$axios.put(`/services/translations/CN`, {
          service_id: this.service_id,
          title_trans: this.title_cn,
          content_trans: this.content_cn,
        });
        // TW
        await this.$axios.put(`/services/translations/TW`, {
          service_id: this.service_id,
          title_trans: this.title_tw,
          content_trans: this.content_tw,
        });
        // EN
        await this.$axios.put(`/services/translations/EN`, {
          service_id: this.service_id,
          title_trans: this.title_en,
          content_trans: this.content_en,
        });
        this.$router.push(`/services`);
      },

      async fetchService() {
        try {
          const res = await this.$axios.get(`/services/${this.$route.params.id}`);
          this.service_id = res.data.id;
          this.title = res.data.title;
          this.content = res.data.content;
          this.image_url = res.data.image_url;
        } catch (err) {
          console.error(err);
        }
      },
      async removeImage() {
        if(this.image_url === '') return
        const url_str_arr = this.image_url.split('/');
        const image_name = url_str_arr.slice(-1);
        try {
          const res = await this.$axios.post(`/s3/delete`, {
            filename: image_name
          });
          this.image_url = '';
        } catch (err) {
          console.log(err)
        }
      },

      async fetchServiceTranslations() {
        try {
          const translations = await this.$axios.post(`/services/translations`, {
            service_id: this.service_id
          });
          translations.data.forEach(translation => {
            const lang = translation.lang;
            switch(lang) {
              case 'CN': {
                this.title_cn = translation.title_trans
                this.content_cn = translation.content_trans
                break;
              }
              case 'TW': {
                this.title_tw = translation.title_trans
                this.content_tw = translation.content_trans
                break;
              }
              case 'EN': {
                this.title_en = translation.title_trans
                this.content_en = translation.content_trans
                break;
              }
            }
          });
        } catch (err) {
            console.error(err);
        }
      }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },
};
